import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Wrapper } from "../components/wrapper"

const WpPostTemplate = ({ data, location, pageContext }) => {
  const {
    date,
    dateJP,
    modified,
    modifiedJP,
    title,
    excerpt,
    categories,
    featuredImage,
    content,
  } = data.wpPost

  return (
    <Layout>
      <Seo
        pagetitle={title}
        pagedesc={excerpt
          .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
          .slice(0, 100)}
        pagepath={location.pathname}
        blogimg={featuredImage?.node.localFile.childImageSharp.gatsbyImageData}
        pageimgw={featuredImage?.node.mediaDetails.width}
        pageimgh={featuredImage?.node.mediaDetails.height}
        publishDate={date}
        updateDate={modified}
      />

      <Wrapper
        date={date}
        dateJP={dateJP}
        modified={modified}
        modifiedJP={modifiedJP}
        title={title}
        categories={categories.nodes}
        featuredImage={featuredImage}
        image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
        alt={title}
        content={content}
        next={pageContext.next}
        previous={pageContext.previous}
      />
    </Layout>
  )
}

export default WpPostTemplate

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      excerpt
      content
      date
      dateJP: date(formatString: "YYYY/MM/DD")
      modified
      modifiedJP: modified(formatString: "YYYY/MM/DD")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          sourceUrl
          title
          mediaDetails {
            width
            height
          }
        }
      }
      categories {
        nodes {
          slug
          name
          id
        }
      }
    }
  }
`
